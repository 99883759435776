import React from 'react'
import {VenturiLogoLong, VenturiLogoLargeLong} from '../../styles/svg/svgIcons'
import {paths} from '../../routes/routesConfig'
import {Statestreet} from './StateStreet/StateStreet'

export const Statestreet_Alp = {
	...Statestreet,
	id: 'stateStreetAlp',
	landingPathFn: (isBorrower?: boolean) => {
		return isBorrower ? paths.alpSttClient : paths.alpStt
	},
	// logo: (
	// 	<div className='header-logo-color'>
	// 		<StateStreetLogo />
	// 	</div>
	// ),
	// login: {
	// 	logo: <StateStreetLogoLarge />,
	// 	fosLink: true,
	// 	fosLogoAtBottom: true,
	// 	carouselDescription: true,
	// },
	logo: (
		<div className='header-logo-color text-center f-24'>
			<VenturiLogoLong />
		</div>
	),
	adminToolAllow: true,
	login: {
		logo: (
			<div className='header-logo-color text-center mb-4'>
				<VenturiLogoLargeLong />
			</div>
		),
		fosLink: true,
		fosLogoAtBottom: true,
		carouselDescription: true,
		disableMfa: false,
	},
	navbar: {
		disableThemeSwitch: true,
		disableDefaultHomepage: false,
		disableHelpIcon: true,
		disableDocumentation: true,
		startCashColumnExist: false,
	},
}
