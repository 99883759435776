import {FinOptsys} from './ProductConfig/FinOptSys/FinOptSys'
import {Statestreet} from './ProductConfig/StateStreet/StateStreet'
import {Statestreet_Alp} from './ProductConfig/StrateStreet_Alp'
import {DH} from './ProductConfig/DH/DH'

interface ClientConfig {
	[clientId: string]: {
		id: string
		logo: any
		login: {
			logo: any
			fosLink: boolean
			carouselDescription: boolean
			fosLogoAtBottom: boolean
			disableMfa: boolean
		}
		landingPath?: string
		adminToolAllow?: boolean
		landingPathFn?: any
		navbar: {
			disableThemeSwitch: boolean
			disableDefaultHomepage: boolean
			disableHelpIcon: boolean
			disableDocumentation: boolean
		}
		peerToPeer: {
			orderTypeTitle: {[orderType: string]: {label: string; title: string}}
			orderType: string[]
			orderEntry: {
				[field: string]: string | boolean | string[] | {[type: string]: string[]} | Record<string, string>[]
				displayStepsCol: boolean
				allowFloatingRate: boolean
				allowAllInRate: boolean
				uploadBasket: boolean
			}
			orderDetail: {
				settlementTab: boolean
				collateralColumnDefsField: string[]
				termAndCondition: boolean
				allowCusipCandDValidation: boolean
				editableDuration: boolean
				disableTradeAndSettleDate: boolean
			}
			orderNegotiation: {
				startCashEditable: boolean
			}
		}
		abcPortfolio: {
			defaultColumn: boolean
			isFetchAllEntriesConfig: boolean
		}
		collateralBasket: {
			fetchBasketItems: {
				optimizedCusipTemplate: boolean
			}
			defaultStrategy: any
			defaultTemplateType: string | null
			basketCreationInputField: {
				template: boolean
				schedule: boolean
				currencies: string[]
				clientRiskTypeTitle: string
			}
			collateralBasketConstruction: {
				[field: string]: string | boolean | string[] | {[type: string]: string[]}
			}
			collateralBasketNegotiation: {
				[field: string]: string | boolean | string[] | {[type: string]: string[]}
			}
			useEnrichedSecurityQuery: boolean
			restrictedColDefs: any[]
		}
		collateralOptimizationDashboard: {
			title: string
			enableObjectiveWeights: boolean
		}
		accountSettings: {
			groups: {
				showTradeType: boolean
			}
			clients: {
				isLeDeposAllowed: boolean
			}
		}
		repo: {
			tradeTicketButton: boolean
		}
		tradeBlotter: {
			orderEntry: {[field: string]: string | boolean | string[] | {[type: string]: string[]} | Record<string, string>[]}
			strategy: any[]
			fieldDecimal: number
			allInPriceFieldDecimal: number
		}
		dashboardSettings: {
			enableRollTrade: boolean
			enableNewCollateralOptDashBoard: boolean
			displayCOFticker: boolean
			displaySODticker: boolean
			startCashColumnExist: boolean
		}
		portfolioUploadConfirm: {
			displayMessage: boolean
			displayRestrictedMessage: boolean
		}
	}
}

export const CLIENT_CONSTANTS = {
	FINOPTSYS: 'finOptSys',
	STATESTREET: 'stateStreet',
	STATESTREET_ALP: 'stateStreetAlp',
	DH: 'dh',
}

export const Config = {
	clientConfigId: CLIENT_CONSTANTS.FINOPTSYS,
	set setActiveConfig(clientConfigId: string) {
		this.clientConfigId = clientConfigId
	},
	get activeConfig() {
		return clientConfig[this.clientConfigId]
	},
}

export const clientConfig: ClientConfig = {
	[CLIENT_CONSTANTS.FINOPTSYS]: FinOptsys,
	[CLIENT_CONSTANTS.DH]: DH,
	[CLIENT_CONSTANTS.STATESTREET]: Statestreet,
	[CLIENT_CONSTANTS.STATESTREET_ALP]: Statestreet_Alp,
}
