import {CLEAR_DYNAMIC_FORM_DETAIL, CLEAR_PRODUCT_TYPE_SUBTYPE} from './MarginAnalysisAction'
import {
	GET_ALL_BASKETS,
	GET_ALL_MARGIN_COMPARISON,
	GET_ALL_TRADES,
	UPDATE_TRADE_BASKET,
	ADD_FORECAST_TRADES,
} from './MarginAnalysisAction'
import {CLEAR_TRADE_BASKET, EDIT_DYNAMIC_FORM} from './MarginAnalysisAction'
import {GET_DYNAMIC_FORM_DETAIL, GET_MARGIN_COMPARISON, GET_PRODUCT_TYPE_AND_SUB_TYPE} from './MarginAnalysisAction'
import {SAVE_DYNAMIC_FORM_DETAIL, SET_MARGIN_COMPARISON_CONTEXT, GET_ALL_SOD_TRADES} from './MarginAnalysisAction'
import {DELETE_TRADE_BY_ID, GET_PRODUCT_RISK_CLASSES} from './MarginAnalysisAction'
import {massageBasketsGridData, massageTradesGridData} from './MarginAnalysisHelper'
import {SOD_FILE_DETAILS} from './SODFiles/SODFileDetailsAction'
import {SAVE_PRE_TRADE_FILE, PRODUCT_DOCUMENT_DETAILS, SAVE_TRADE_LISTS} from './TradeUpload/TradeUploadAction'

export interface MarginAnalysis {
	loading: boolean
	allTrades: any
	tradeBasket: any
	editDynamicForm: any
	allSODTrades: any
	forecastData: any
	allBaskets: any
	comparisonDetails: any
	contextComparisonDetails: any
	deleting: boolean
	dynamicFormDetail: any
	dynamicFormDetailSaved: any[]
	productTypeAndSubType: any[]
	productRiskClasses: any[]
	sodFiledDetails: any
	documentDetails: any
	uploadFileDetails: any
	uploadFileLoading: boolean
}

const initialState: MarginAnalysis = {
	loading: false,
	allTrades: [],
	tradeBasket: [],
	editDynamicForm: {},
	allSODTrades: [],
	forecastData: {},
	allBaskets: [],
	comparisonDetails: [],
	contextComparisonDetails: null,
	deleting: false,
	dynamicFormDetail: null,
	dynamicFormDetailSaved: null,
	productTypeAndSubType: null,
	productRiskClasses: null,
	sodFiledDetails: {headers: [], trades: []},
	documentDetails: {},
	uploadFileDetails: {},
	uploadFileLoading: false,
}

export function MarginAnalysisReducer(state = initialState, action: any) {
	switch (action.type) {
		case CLEAR_DYNAMIC_FORM_DETAIL:
			return {...state, dynamicFormDetail: null}

		case CLEAR_PRODUCT_TYPE_SUBTYPE:
			return {...state, productTypeAndSubType: null, productRiskClasses: null}

		case GET_ALL_TRADES.triggered:
			return {...state}
		case GET_ALL_TRADES.succeeded:
			const allTrades = action.response && action.response.data ? action.response.data : []
			return {
				...state,
				allTrades: massageTradesGridData(allTrades),
			}
		case GET_ALL_TRADES.failed:
			return {...state, allTrades: []}

		case UPDATE_TRADE_BASKET:
			const updateTrades = action.response
			return {
				...state,
				tradeBasket: updateTrades,
			}
		case ADD_FORECAST_TRADES:
			return {
				...state,
				forecastData: action.response,
			}
		case CLEAR_TRADE_BASKET:
			return {
				...state,
				tradeBasket: [],
				dynamicFormDetailSaved: null,
			}
		case EDIT_DYNAMIC_FORM:
			return {
				...state,
				editDynamicForm: action.value,
			}

		case GET_DYNAMIC_FORM_DETAIL.triggered:
			return {...state, dynamicFormDetail: null}
		case GET_DYNAMIC_FORM_DETAIL.succeeded:
			return {...state, dynamicFormDetail: action.response.data}
		case GET_DYNAMIC_FORM_DETAIL.failed:
			return {...state, dynamicFormDetail: [], editDynamicForm: {rowId: null, enableEdit: false}}

		case GET_PRODUCT_TYPE_AND_SUB_TYPE.triggered:
			return {...state, productTypeAndSubType: null}
		case GET_PRODUCT_TYPE_AND_SUB_TYPE.succeeded:
			return {...state, productTypeAndSubType: action.response.data}
		case GET_PRODUCT_TYPE_AND_SUB_TYPE.failed:
			return {...state, productTypeAndSubType: []}

		case SAVE_DYNAMIC_FORM_DETAIL.triggered:
			return {...state, dynamicFormDetailSaved: null}
		case SAVE_DYNAMIC_FORM_DETAIL.succeeded:
			return {...state, dynamicFormDetailSaved: action.response.data}
		case SAVE_DYNAMIC_FORM_DETAIL.failed:
			return {...state, dynamicFormDetailSaved: null}

		case GET_ALL_BASKETS.triggered:
			return {...state}
		case GET_ALL_BASKETS.succeeded:
			const allBaskets = action.response && action.response.data ? action.response.data : []
			return {...state, allBaskets: massageBasketsGridData(allBaskets)}
		case GET_ALL_BASKETS.failed:
			return {...state, allBaskets: []}

		case GET_MARGIN_COMPARISON.triggered:
			return {...state}
		case GET_MARGIN_COMPARISON.succeeded:
			// const comparisonDetails = action.response && action.response.data ? action.response.data : []
			return {...state}
		case GET_MARGIN_COMPARISON.failed:
			return {...state, comparisonDetails: []}

		case GET_ALL_MARGIN_COMPARISON.triggered:
			return {...state}
		case GET_ALL_MARGIN_COMPARISON.succeeded:
			const comparisonDetails = action.response && action.response.data ? action.response.data : []
			return {...state, comparisonDetails}
		case GET_ALL_MARGIN_COMPARISON.failed:
			return {...state, comparisonDetails: []}

		case GET_ALL_SOD_TRADES.triggered:
			return {...state}
		case GET_ALL_SOD_TRADES.succeeded:
			const allSODTrades = action.response && action.response.data ? action.response.data : []
			return {...state, allSODTrades: massageTradesGridData(allSODTrades)}
		case GET_ALL_SOD_TRADES.failed:
			return {...state, allSODTrades: []}

		case SET_MARGIN_COMPARISON_CONTEXT:
			const contextComparisonDetails = action.payload && action.payload.response
			return {...state, contextComparisonDetails}

		case GET_PRODUCT_RISK_CLASSES.triggered:
			return {...state, productRiskClasses: null}
		case GET_PRODUCT_RISK_CLASSES.succeeded:
			return {...state, productRiskClasses: action.response.data}
		case GET_PRODUCT_RISK_CLASSES.failed:
			return {...state, productRiskClasses: []}

		case DELETE_TRADE_BY_ID.triggered:
			return {...state, deleting: true}
		case DELETE_TRADE_BY_ID.failed:
			return {...state, deleting: false}
		case DELETE_TRADE_BY_ID.succeeded:
			return {
				...state,
				deleting: false,
			}

		case SOD_FILE_DETAILS.triggered:
		case SOD_FILE_DETAILS.failed:
			return {...state, sodFiledDetails: {headers: [], trades: []}}
		case SOD_FILE_DETAILS.succeeded:
			return {...state, sodFiledDetails: action.response.data}

		case PRODUCT_DOCUMENT_DETAILS.triggered:
			return {...state, documentDetails: null}
		case PRODUCT_DOCUMENT_DETAILS.succeeded:
			return {...state, documentDetails: action.response.data}
		case PRODUCT_DOCUMENT_DETAILS.failed:
			return {...state, documentDetails: null}

		case SAVE_PRE_TRADE_FILE.triggered:
			return {...state, uploadFileLoading: true, uploadFileDetails: null}
		case SAVE_PRE_TRADE_FILE.succeeded:
			return {...state, uploadFileLoading: false, uploadFileDetails: action.response.data}
		case SAVE_PRE_TRADE_FILE.failed:
			return {...state, uploadFileLoading: false, uploadFileDetails: null}

		case SAVE_TRADE_LISTS.triggered:
			return {...state, uploadFileDetails: null}
		case SAVE_TRADE_LISTS.succeeded:
			return {...state, uploadFileDetails: action.response.data}
		case SAVE_TRADE_LISTS.failed:
			return {...state, uploadFileDetails: null}
	}
	return state
}

