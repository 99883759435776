import memoizeOne from 'memoize-one'
import isDeepEqual from 'lodash.isequal'
import {ArrayUtil, ObjectUtil} from 'helper-util'

export const getProductGroupDropdownItem = memoizeOne(productRiskClasses => {
	const productGroupDropdownItem = ArrayUtil.isEmpty(productRiskClasses) ? null : []
	productRiskClasses &&
		productRiskClasses.forEach(item => {
			const group = {
				id: item.name,
				label: item.name,
				value: item.name,
				inSOD: item.inSOD,
			}
			productGroupDropdownItem.push(group)
		})
	return productGroupDropdownItem || []
}, isDeepEqual)

export const getProductTypeDropdownItem = memoizeOne(productTypeAndSubType => {
	const productTypeDropdownItem = ArrayUtil.isEmpty(productTypeAndSubType) ? null : []
	productTypeAndSubType &&
		productTypeAndSubType.forEach(item => {
			const product = {
				id: item.id,
				label: item.name,
				value: item.id,
				inSOD: item.inSOD,
			}
			productTypeDropdownItem.push(product)
		})
	return productTypeDropdownItem || []
}, isDeepEqual)

export const getProductSubTypeDropdownItem = memoizeOne((productType, productTypeAndSubType) => {
	const productSubTypeDropdownItem = []
	const selectedProduct =
		productType && productTypeAndSubType ? productTypeAndSubType.filter(item => item.id === productType.id) : null
	!ArrayUtil.isEmpty(selectedProduct) &&
		selectedProduct[0].subTypes.forEach(item => {
			const productSubType = {
				id: item.id,
				label: item.name,
				value: item.id,
				disableChild: item.disableChild,
				inSOD: item.inSOD,
			}
			productSubTypeDropdownItem.push(productSubType)
		})
	return productSubTypeDropdownItem
}, isDeepEqual)

export const getProductSubTypeTradeTypes = memoizeOne((productType, productTypeAndSubType) => {
	const selectedProduct =
		productType && productTypeAndSubType ? productTypeAndSubType.filter(item => item.id === productType.id) : null
	const subTypes =
		(!ArrayUtil.isEmpty(selectedProduct) && selectedProduct[0].subTypes && selectedProduct[0].subTypes[0]) || []
	const tradeTypes = {
		tradeTypes1: subTypes.tradeTypes1,
		tradeTypes2: subTypes.tradeTypes2,
	}

	return tradeTypes
}, isDeepEqual)

export const getFieldDetail = memoizeOne(
	(isAdvanceDisplayType: boolean, enableEdit: boolean, dynamicFormDetail: any) => {
		const quickFieldDetail = []
		const quickFieldSection = []
		const advanceFieldDetail = []
		const defaultSubTemplates = []
		const optionalTemplates = []
		!ObjectUtil.isEmpty(dynamicFormDetail) &&
			dynamicFormDetail.templates.forEach(template => {
				if (template.group && !template.optionalTemplate) {
					defaultSubTemplates.push(template.id)
				}
				const subTemplate = []
				template.fields.forEach(field => {
					const detail = {
						id: template.id,
						type: field.displayType,
						field,
					}
					if (field.displayType === 'quick') {
						quickFieldDetail.push(detail)
						subTemplate.push(detail)
					}
					if (['advanced', 'edit'].includes(field.displayType)) advanceFieldDetail.push(detail)
					template.group && !optionalTemplates.includes(template.id) && optionalTemplates.push(template.id)
				})
				!ArrayUtil.isEmpty(subTemplate) && quickFieldSection.push(subTemplate)
			})

		const fieldDetail = isAdvanceDisplayType || enableEdit ? {quickFieldDetail, advanceFieldDetail} : {quickFieldDetail}
		return {fieldDetail, defaultSubTemplates, optionalTemplates, quickFieldSection}
	},
	isDeepEqual
)

export const getFieldDetailTemplateWise = memoizeOne((dynamicFormDetail: any, enableEdit: boolean) => {
	const fieldDetailsByTemplate =
		dynamicFormDetail &&
		dynamicFormDetail['templates'] &&
		dynamicFormDetail['templates'].reduce((acc, template) => {
			acc[template.id] = template.fields
				.map(field => ({id: template.id, type: field.displayType, field: field}))
				.filter(field => field.type === 'advanced' || field.type === 'quick' || (enableEdit && field.type === 'edit'))
			return acc
		}, {})
	return {fieldDetailsByTemplate}
}, isDeepEqual)

export const getMandatoryField = memoizeOne((isAdvanceDisplayType: boolean, dynamicFormDetail: any) => {
	const mandatoryField = []
	Object.values(dynamicFormDetail).forEach((fieldCategory: any[]) => {
		fieldCategory.forEach(item => {
			const {field} = item
			if ((isAdvanceDisplayType ? true : field.displayType === 'quick') && field.isMandatory) mandatoryField.push(item)
		})
	})
	return mandatoryField
}, isDeepEqual)

export const isSubmitButtonDisabled = memoizeOne((mandatoryField: any[], tradeData: any) => {
	let submitButtonEnabled = true
	!ObjectUtil.isEmpty(tradeData) &&
		mandatoryField.forEach(template => {
			if (submitButtonEnabled) submitButtonEnabled = Boolean(tradeData[template.id][template.field.id])
		})
	return !submitButtonEnabled
}, isDeepEqual)

export const getRowData = memoizeOne(data => {
	if (data === null) return data

	data.forEach(item => {
		item.view = true
	})
	return data
}, isDeepEqual)

export const massageTradesGridData = memoizeOne(allTrades => {
	if (ArrayUtil.isEmpty(allTrades)) {
		return []
	}
	return allTrades.map(data => {
		const createdDateArr = data.createdDate
		const modifiedDateArr = data.modifiedDate
		if (ArrayUtil.isEmpty(createdDateArr)) return data
		if (ArrayUtil.isEmpty(modifiedDateArr)) return data
		return {
			...data,
			createdDate: new Date(
				Date.UTC(
					createdDateArr[0],
					createdDateArr[1] - 1,
					createdDateArr[2],
					createdDateArr[3],
					createdDateArr[4],
					createdDateArr[5]
				)
			),
			modifiedDate: new Date(
				Date.UTC(
					modifiedDateArr[0],
					modifiedDateArr[1] - 1,
					modifiedDateArr[2],
					modifiedDateArr[3],
					modifiedDateArr[4],
					modifiedDateArr[5]
				)
			),
		}
	})
}, isDeepEqual)

export const massageBasketsGridData = memoizeOne(allBaskets => {
	if (ArrayUtil.isEmpty(allBaskets)) {
		return []
	}
	return allBaskets.map(data => {
		const createdDateArr = data.createdDate
		const modifiedDateArr = data.modifiedDate
		if (ArrayUtil.isEmpty(createdDateArr)) return data
		if (ArrayUtil.isEmpty(modifiedDateArr)) return data
		return {
			...data,
			tradeIdList: `[ ${(data.trades || []).map(trade => trade.id).join(', ')} ]`,
			createdDate: new Date(
				Date.UTC(
					createdDateArr[0],
					createdDateArr[1] - 1,
					createdDateArr[2],
					createdDateArr[3],
					createdDateArr[4],
					createdDateArr[5]
				)
			),
			modifiedDate: new Date(
				Date.UTC(
					modifiedDateArr[0],
					modifiedDateArr[1] - 1,
					modifiedDateArr[2],
					modifiedDateArr[3],
					modifiedDateArr[4],
					modifiedDateArr[5]
				)
			),
		}
	})
}, isDeepEqual)

export const sampleDataGraph = [
	{
		category: 'CITGGLTD',
		negative1: -2000000,
		positive1: 6000000,
		positive2: 1000000,
	},
	{
		category: 'MACQLTDX',
		negative1: 11121528.84919002,
		positive1: 30,
		positive2: 10,
	},
	{
		category: 'BARCBPLC',
		negative1: -2,
		positive1: 0,
		positive2: 10,
	},
	{
		category: 'BOFANAXX',
		negative1: 10,
		positive1: 70,
		positive2: 10,
	},
	{
		category: 'CITBNAXX',
		negative1: 20,
		positive1: 63,
		positive2: 10,
	},
]

export const getFundDropdownItem = memoizeOne(allFund => {
	const fundDropdownItem = ArrayUtil.isEmpty(allFund) ? null : []
	allFund &&
		allFund.forEach(item => {
			const fund = {
				id: item.fundId,
				label: item.fullName + ' - ' + (item.internalCode1 || 'N/A'),
				value: item.internalCode1,
			}
			fundDropdownItem.push(fund)
		})
	return fundDropdownItem
}, isDeepEqual)

export const getPortfolioDropdownItem = memoizeOne(allPortfolio => {
	const portfolioDropdownItem = ArrayUtil.isEmpty(allPortfolio) ? null : []
	allPortfolio &&
		allPortfolio.forEach(item => {
			const portfolio = {
				id: item.portfolioId,
				label: item.fullName,
				value: item.internalCode1,
			}
			portfolioDropdownItem.push(portfolio)
		})
	return portfolioDropdownItem
}, isDeepEqual)

export const updateDynamicFormDetails = memoizeOne(dynamicFormDetail => {
	if (ObjectUtil.isEmpty(dynamicFormDetail) || ArrayUtil.isEmpty(dynamicFormDetail.templates)) return {}
	dynamicFormDetail.templates.forEach(template => {
		template.fields.forEach(field => {
			field.id = field.header
		})
	})
}, isDeepEqual)

export const createDynamicObject = (keys, value, tradeDataPayload) => {
	const keysArray = keys && keys.split('.')

	let dynamicObject = tradeDataPayload
	if (!ArrayUtil.isEmpty(keysArray)) {
		keysArray.slice(0, -1).forEach(key => {
			if (!(key in dynamicObject)) {
				dynamicObject[key] = {}
			}
			dynamicObject = dynamicObject[key]
		})

		const finalKey = keysArray[keysArray.length - 1]
		if (!(finalKey in dynamicObject)) {
			dynamicObject[finalKey] = value
		}
	}
	return tradeDataPayload
}

export const dynamicChildTemplate = (dynamicFormDetail, tradeDataPayload, selectedSubTemplates) => {
	dynamicFormDetail.templates.forEach(template => {
		const {parentTemplate, group, id} = template
		if (group) {
			if (parentTemplate) {
				const keys = parentTemplate.split('.')
				const [parentKey, childKey] = keys
				if (Array.isArray(tradeDataPayload[parentKey])) {
					if (!tradeDataPayload[parentKey][childKey][group]) {
						tradeDataPayload[parentKey][childKey][group] = []
					}
					if (tradeDataPayload[id] && selectedSubTemplates.includes(id))
						tradeDataPayload[parentKey][childKey][group].push(tradeDataPayload[id])
					if (tradeDataPayload[parentKey][childKey][group].length === 0)
						delete tradeDataPayload[parentKey][childKey][group]
				}
				if (typeof tradeDataPayload[parentTemplate] === 'object') {
					if (!tradeDataPayload[parentTemplate][group]) {
						tradeDataPayload[parentTemplate][group] = []
					}
					if (tradeDataPayload[id] && selectedSubTemplates.includes(id))
						tradeDataPayload[parentTemplate][group].push(tradeDataPayload[id])
					if (tradeDataPayload[parentTemplate][group].length === 0) delete tradeDataPayload[parentTemplate][group]
				}
			} else {
				if (!tradeDataPayload[group]) {
					tradeDataPayload[group] = []
				}
				if (tradeDataPayload[id] && selectedSubTemplates.includes(id))
					tradeDataPayload[group].push(tradeDataPayload[id])
				if (tradeDataPayload[group].length === 0) delete tradeDataPayload[group]
			}
			delete tradeDataPayload[id]
		}
	})
	return tradeDataPayload
}

export const getTradeDataPayload = (dynamicFormDetail, tradeData, selectedSubTemplates) => {
	let tradeDataPayload = {}
	dynamicFormDetail.templates.forEach(item => {
		const key = item.id
		if (Array.isArray(item.fields)) {
			item.fields.forEach(field => {
				const {id, payloadGroup} = field
				if (
					(payloadGroup && !item.optionalTemplate) ||
					(payloadGroup && item.optionalTemplate && selectedSubTemplates.includes(item.id))
				)
					tradeDataPayload = createDynamicObject(payloadGroup, tradeData[key][id], tradeDataPayload)
			})
		}
	})
	tradeDataPayload = dynamicChildTemplate(dynamicFormDetail, tradeDataPayload, selectedSubTemplates)
	return tradeDataPayload
}

export const getUpdatedMandatoryFields = memoizeOne((mandatoryField, allSubTemplateIds, selectedSubTemplates) => {
	const filteredSubTemplates = allSubTemplateIds && allSubTemplateIds.filter(id => !selectedSubTemplates.includes(id))
	return mandatoryField.filter(field => !filteredSubTemplates.includes(field.id))
}, isDeepEqual)

export const getAutoSelectedSubTemplate = (optionalTemplates, enableEdit, advanceFieldDetail, tradeData) => {
	const autoSelectTemplated = []
	let obj = {}
	optionalTemplates.forEach(item => {
		obj[item] = {}
		item &&
			(advanceFieldDetail || []).forEach(list => {
				if (list.id === item) {
					obj[item][list.field.id] = enableEdit
						? list.field.defaultValue
						: tradeData[item] && tradeData[item][list.field.header]
				}
			})

		const result = obj[item] && checkObjectValues(obj[item])
		result && autoSelectTemplated.push(item)
	})
	return autoSelectTemplated
}

const checkObjectValues = obj => {
	if (ObjectUtil.isEmpty(obj)) {
		return false
	}
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) {
			if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
				return false
			}
		}
	}
	return true
}

