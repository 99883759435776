import lodash from 'lodash'
import {FormatUtil} from 'helper-util'
import {
	fontWeight,
	noCommaFourDecimal,
	successDangerClass,
	noCommaNoDecimal,
	commaTwoDecimal,
	noCommaSixDecimal,
	commaSixDecimal,
	numberOnly,
} from './AgGridHelper'
import {commaNoDecimal, noCommaTwoDecimal, noCommaTwoDecimalPercent, commaTwoDecimalPercent} from './AgGridHelper'
import {yyyyMmDd, yyyyMmDdHHmmss, HHmmss} from './AgGridHelper'
import {noPinnedFooter, pinnedLeft, noColumnAction, iconWidth, encryptURL} from './AgGridHelper'
import {paths} from '../../routes/routesConfig'
import {formatWithDateAndTime} from '../../utils/dateUtil'
import {getInefficiencyClassName} from '../../utils/featureUtils'

/** NOTE to DEV
 * defination should be arranged in a to z order
 * only column common across app should be used here
 * defination key should be field value eg. abc: { field: 'abc' }
 * to override global defination use Object.assign({}, import defination, your defination)
 * try to fit your column into existing defination, avoid creating new defination as much as possible
 */

const $ = {
	acceptField: {
		headerName: '',
		field: 'accept',
		...pinnedLeft,
		...noColumnAction,
		...iconWidth,
		cellRenderer: params => {
			if (params.value === true)
				return '<i class="far fa-check-circle text-secondary cursor-pointer" title="Accept"></i>'
			return null
		},
	},
	checkBoxField: {
		headerName: '',
		field: '',
		...pinnedLeft,
		...noColumnAction,
		...iconWidth,
		headerCheckboxSelection: true,
		checkboxSelection: true,
	},
	deleteField: {
		headerName: '',
		field: 'delete',
		...pinnedLeft,
		...noColumnAction,
		...iconWidth,
		cellRenderer: params => {
			if (params.value === true)
				return '<i class="fa fa-trash-o text-info text-danger cursor-pointer" title="Delete"></i>'
			return null
		},
	},
	editField: {
		headerName: '',
		field: 'edit',
		...pinnedLeft,
		...noColumnAction,
		...iconWidth,
		cellRenderer: params => {
			if (params.value === true)
				return '<i class="fa fa-pencil-square-o text-secondary cursor-pointer" title="Edit"></i>'
			return null
		},
	},
	infoField: {
		headerName: '',
		field: 'info',
		...pinnedLeft,
		...noColumnAction,
		...iconWidth,
		cellRenderer: params => {
			if (params.value === true) return '<i class="fa fa-info-circle text-primary cursor-pointer" title="Detail"></i>'
			return null
		},
	},
	selectField: {
		headerName: '',
		field: 'select',
		...pinnedLeft,
		...noColumnAction,
		...iconWidth,
		cellRenderer: params => {
			if (params.value === true) return '<i class="fa fa-reply text-secondary cursor-pointer" title="Select"></i>'
			return null
		},
	},
	tableTitle: {
		headerName: '',
		field: 'tableTitle',
	},
	viewField: {
		headerName: '',
		field: 'view',
		...pinnedLeft,
		...noColumnAction,
		...iconWidth,
		cellRenderer: params => {
			if (params.value === true) return '<i class="far fa-eye text-primary cursor-pointer f-16" title="View"></i>'
			return null
		},
	},
	tradeStatusIcon: {
		headerName: '',
		field: 'tradeStatusIcon',
		...iconWidth,
	},
}

const a = {
	abcmCategoryName: {headerName: 'ABCM Category Name', field: 'abcmCategoryName'},
	abcmCategoryType: {headerName: 'ABCM Category Type', field: 'abcmCategoryType'},
	abpmCharge: {headerName: 'ABPM Charge', field: 'abpmCharge'},
	acknowledgedBy: {headerName: 'Acknowledged By', field: 'acknowledgedBy'},
	accruedInterest: {headerName: 'Accrued Interest', field: 'accruedInterest'},
	actions: {headerName: 'Action', field: 'actions', ...noPinnedFooter},
	activityType: {
		headerName: 'Activity',
		field: 'activityType',
		cellRenderer: params => {
			const {value, data} = params
			const notClickable =
				FormatUtil.text.toLowerCase(value) === 'n/a' ||
				(data && FormatUtil.text.toLowerCase(data.portfolioEntryType) !== 'start of day')
			return notClickable
				? value
				: data
				? `<span class='text-link' onclick='return window.open("/activity-type/${params.data.id}")'>${value}</span>`
				: ''
		},
	},
	accrual: {headerName: 'Accrual', field: 'accrual', ...commaTwoDecimal},
	accountNames: {headerName: 'Accounts', field: 'accountNames'},
	accountType: {headerName: 'Account Type', field: 'accountType'},
	actionComment: {headerName: 'Action Comment', field: 'actionComment'},
	adjustedQuantity: {headerName: 'Adjusted Quantity', field: 'adjustedQuantity', ...commaNoDecimal},
	adjustedCash: {headerName: 'Adjusted Cash', field: 'adjustedCash', ...commaNoDecimal},
	agreementType: {headerName: 'Agreement Type', field: 'agreementType'},
	agreementTypes: {headerName: 'Agreement Type', field: 'agreementTypes'},
	allInPriceAmount: {headerName: 'All in Price', field: 'allInPriceAmount', ...noCommaSixDecimal},
	allInRate: {headerName: 'All In Rate (%)', field: 'allInRate', ...noCommaSixDecimal},
	allocated: {headerName: 'Allocated', field: 'allocated', ...commaNoDecimal, ...noPinnedFooter},
	allocatedQuantity: {headerName: 'Matched Quantity', field: 'allocatedQuantity', ...commaNoDecimal, ...noPinnedFooter},
	allocatedNotional: {headerName: 'Matched Notional', field: 'allocatedNotional', ...commaNoDecimal},
	allocationName: {headerName: 'Allocation Name', field: 'allocationName'},
	allowedNtl: {headerName: 'Avail Notl', field: 'allowedNtl', ...commaNoDecimal},
	annotation: {headerName: 'Annotation', field: 'annotation'},
	approvedQuantity: {headerName: 'Approved Quantity', field: 'approvedQuantity', ...commaNoDecimal, ...noPinnedFooter},
	approvedTime: {headerName: 'Approved Time', field: 'approvedTime', ...yyyyMmDdHHmmss},
	approveType: {headerName: 'Approve Type', field: 'approveType'},
	asset: {headerName: 'Asset', field: 'asset'},
	assetBasedCharge: {headerName: 'Asset Based Cost', field: 'assetBasedCharge', ...commaNoDecimal},
	assetClassification: {headerName: 'Asset Classification', field: 'assetClassification'},
	assetMaturityDate: {headerName: 'Asset Maturity Date', field: 'assetMaturityDate', ...yyyyMmDd},
	assetResidualMaturity: {headerName: 'Asset Residual Maturity (Years)', field: 'maturity'},
	assetSubType: {headerName: 'Asset SubType', field: 'assetSubType'},
	assetType: {headerName: 'Asset Type', field: 'assetType'},
	assignedTo: {headerName: 'Assigned To', field: 'assignedTo'},
	assetUtilization: {
		headerName: 'Custodian Asset Utilization',
		field: 'custodianAssetUtilization',
		...numberOnly,
		...noPinnedFooter,
	},
	assetUtilizationPercent: {
		headerName: 'Asset Utilization (%)',
		field: 'assetUtilizationPercent',
		...commaTwoDecimal,
		...noPinnedFooter,
	},
	assetUtilizationImpactPercent: {
		headerName: 'Impact Asset Utilization (%)',
		field: 'assetUtilizationImpactPercent',
		...commaTwoDecimal,
		...noPinnedFooter,
	},
	authorizer: {headerName: 'Authorizer', field: 'authorizer'},
	availableNotional: {headerName: 'Available Notional', field: 'availableNotional', ...commaNoDecimal},
	availableQuantity: {
		headerName: 'Available Quantity',
		field: 'availableQuantity',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	availableRefId: {headerName: 'Available Ref Id', field: 'inventoryRefId'},
	availabilitiesContributionAmount: {
		headerName: 'Availability Notional',
		field: 'availabilitiesContributionAmount',
		...commaNoDecimal,
	},
}

const b = {
	balancesheetNotional: {headerName: 'Balance Sheet Notional (USD)', field: 'balancesheetNotional', ...commaNoDecimal},
	baseCollateral: {headerName: 'Base Collateral', field: 'baseCollateral', ...commaNoDecimal},
	baseCcy: {headerName: 'Base Ccy', field: 'baseCcy'},
	baseNotional: {headerName: 'Base Notional', field: 'baseNotional', ...commaNoDecimal},
	basePriceAmount: {headerName: 'Base Price', field: 'basePriceAmount', ...commaSixDecimal, ...noPinnedFooter},
	basketNotional: {headerName: 'Basket Notional', field: 'basketNotional', ...commaNoDecimal},
	baseNotionalAmount: {headerName: 'Base Notional', field: 'baseNotionalAmount', ...commaNoDecimal},
	basketType: {headerName: 'Basket Type', field: 'basketType'},
	basketObjective: {headerName: 'Basket Objective', field: 'basketObjective'},
	basketRate: {headerName: 'Basket All In Rate (%)', field: 'interpolatedRate', ...commaSixDecimal, ...noPinnedFooter}, //FIX
	baseNotionalCurrency: {headerName: 'Base Notional Currency', field: 'baseNotionalCurrency'},
	balanceSheetContributionAmount: {
		headerName: 'Balance Sheet',
		field: 'balanceSheetContributionAmount',
		...commaNoDecimal,
	},
	balancesheetContributionFromBasket: {
		headerName: 'Balancesheet Impact',
		field: 'balancesheetContributionFromBasket',
		...commaNoDecimal,
	},
	basketQuantity: {headerName: 'Basket Quantity', field: 'basketQuantity', ...commaNoDecimal, ...noPinnedFooter},
	basketWeightage: {
		headerName: '% Weight of Basket',
		field: 'basketWeightage',
		...noCommaTwoDecimalPercent,
		...noPinnedFooter,
	},
	bbgTicker: {headerName: 'BBG Ticker', field: 'bbgTicker'},
	bbId: {headerName: 'BBID', field: 'bbId'},
	benchmark: {headerName: 'Benchmark', field: 'benchmark'},
	benchmarkCost: {headerName: 'Benchmark Cost', field: 'bench_cost', ...commaNoDecimal}, //FIX
	benchmarkNotl: {headerName: 'Benchmark Notional', field: 'Benchmark Notl', ...commaNoDecimal}, //FIX
	benchmarkRate: {headerName: 'Benchmark Rate (%)', field: 'bench_rate', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	bbgGlobalIdentifier: {headerName: 'BBG Global Company Identifier', field: 'bbgGlobalIdentifier'},
	bticBid: {headerName: 'Bid', field: 'btic_bid', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	bticAsk: {headerName: 'Offer', field: 'btic_ask', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	bvcSaving: {headerName: 'BvC', field: 'bvcSaving', ...commaNoDecimal},
	bvncSaving: {headerName: 'BvNC', field: 'bvncSaving', ...commaNoDecimal},
	borrow: {headerName: 'Borrow', field: 'borrow', ...fontWeight, ...successDangerClass(false), ...commaNoDecimal},
	borrowVsCash: {headerName: 'BvC Qty', field: 'borrowVsCash', ...commaNoDecimal},
	borrowVsCashNotional: {headerName: 'BvC Notional', field: 'borrowVsCashNotional', ...commaNoDecimal},
	borrowVsNonCash: {headerName: 'BvNC Qty', field: 'borrowVsNonCash', ...commaNoDecimal},
	borrowVsNonCashNotional: {headerName: 'BvNC Notional', field: 'borrowVsNonCashNotional', ...commaNoDecimal},
	borrowContributionAmount: {headerName: 'Borrow', field: 'borrowContributionAmount', ...commaNoDecimal},
	borrowFee: {headerName: 'Borrow Fee', field: 'borrowFee', ...commaTwoDecimal, ...noPinnedFooter},
	borrowerLei: {headerName: 'Borrower Legal Entity Id', field: 'borrowerLei'},
	borrowerSubAccount: {headerName: 'Borrower Sub Account', field: 'borrowerSubAccount'},
	book: {headerName: 'Book', field: 'book'},
	bucket: {headerName: 'Bucket', field: 'bucket'},
	businessCode: {headerName: 'business_code', field: 'businessCode'},
	businessCodeCollOpt: {headerName: 'Business Code', field: 'businessCode'},
	businessDate: {headerName: 'Price Date', field: 'businessDate', ...yyyyMmDd},
	businessDateCollOpt: {headerName: 'Date', field: 'date', ...yyyyMmDd},
	businessUnitFullName: {headerName: 'Business Unit', field: 'businessUnitFullName'},
	buySellInd: {
		headerName: 'Buy Sell Indicator',
		field: 'buySellInd',
		cellRenderer: params => {
			const isTradeTypeCP = FormatUtil.text.toUpperCase(params.data.strategy) === 'OUTRIGHT_CP'
			const isSell = FormatUtil.text.toLowerCase(params.value) === 'sell'
			const isLoan =
				FormatUtil.text.toLowerCase(params.value) === 'loan' || FormatUtil.text.toLowerCase(params.value) === 'repo'
			const customClass = `badge badge-pill text-white ${
				(isTradeTypeCP && isSell) || isLoan ? 'bg-rajah' : 'bg-flamingo'
			}`
			return params.value ? `<span class='${customClass}'>${params.value}</span>` : ''
		},
	},
}

const c = {
	callable: {headerName: 'Callable', field: 'callable', ...noPinnedFooter},
	callableDate: {headerName: 'Callable Date', field: 'callableDate', ...yyyyMmDd},
	calTradeIM: {headerName: 'Calc Trade IM', field: 'CalTradeIM'},
	cashAmount: {headerName: 'Cash', field: 'cashAmount', ...commaNoDecimal},
	cashCurrency: {headerName: 'Cash Currency', field: 'cashCurrency'},
	cashNeutral: {headerName: 'Cash Neutral', field: 'cashNeutral'},
	ccy: {headerName: 'Currency Code', field: 'ccy'},
	centsPerStock: {headerName: 'Cents Per Stock', field: 'centsPerStock', ...noCommaFourDecimal, ...noPinnedFooter},
	classification: {headerName: 'Classification', field: 'classification'},
	clients: {headerName: 'Clients', field: 'clients'},
	clientEarnings: {headerName: 'Client Earning', field: 'clientEarnings', ...commaTwoDecimal, ...noPinnedFooter},
	clientName: {headerName: 'Client Name', field: 'clientName'},
	clientRank: {headerName: 'Client Rank', field: 'clientRank'},
	clientRiskType: {headerName: 'Client Profile', field: 'clientRiskType'},
	clientRanking: {headerName: 'Client Ranking', field: 'clientRanking', ...commaNoDecimal, ...noPinnedFooter},
	clientType: {headerName: 'Client Type', field: 'clientType'},
	closingPrice: {headerName: 'Closing Price', field: 'closingPrice', ...commaSixDecimal, ...noPinnedFooter},
	cpty: {headerName: 'Counterparty', field: 'cpty'},
	cof: {headerName: 'COF', field: 'cof'},
	cofReferenceRate: {headerName: 'COF Rate (%)', field: 'cofReferenceRate'},
	collateralBasketPurpose: {headerName: 'Collateral Basket Purpose', field: 'collateralBasketPurpose'},
	collateralBasketType: {headerName: 'Collateral Basket Type', field: 'collateralBasketType'},
	collateralAmount: {headerName: 'Collateral', field: 'collateralAmount', ...commaNoDecimal},
	collateralValue: {headerName: 'Collateral', field: 'Collateral Value', ...commaNoDecimal}, //FIX
	collateralCurrency: {headerName: 'Collateral Currency', field: 'collateralCurrency'},
	collateralCcy: {headerName: 'Collateral Ccy', field: 'collateralCcy'},
	collateralPrice: {headerName: 'Collateral Price', field: 'collateralPrice', ...commaTwoDecimal},
	collateralProvider: {headerName: 'Collateral Provider', field: 'collateralProvider', ...noPinnedFooter},
	collateralReceiver: {headerName: 'Collateral Receiver', field: 'collateralReceiver', ...noPinnedFooter},
	collateralType: {headerName: 'Collateral Type', field: 'collateralType', ...noPinnedFooter},
	comments: {headerName: 'Comments', field: 'comments'},
	componentGroupNames: {headerName: 'Component Groups', field: 'componentGroupNames'},
	contractValue: {headerName: 'Contract Value', field: 'contractValue', ...commaNoDecimal},
	contractTypes: {headerName: 'Contract Types', field: 'contractTypes'},
	// agreementTypes: {headerName: 'Agreement Type', field: 'agreementTypes'},
	costAnn: {headerName: 'Cost Ann', field: 'costAnn', ...commaNoDecimal},
	cost: {headerName: 'Cost(%)', field: 'cost', ...commaTwoDecimal},
	costImpact: {headerName: 'Cost Impact(bps)', field: 'costImpact', ...commaTwoDecimal},
	computed: {headerName: 'Available Qty', field: 'computed', ...commaNoDecimal, ...noPinnedFooter},
	counterParty: {headerName: 'Counterparty', field: 'counterParty'},
	counterparty: {headerName: 'Counterparty', field: 'counterparty'},
	counterParties: {
		headerName: 'Counterparty',
		field: 'counterParties',
		cellRenderer: params => params.value.join(', '),
	},
	counterPartyName: {headerName: 'Counterparty', field: 'counterPartyName'},
	counterPartyStatus: {headerName: 'Status', field: 'counterPartyStatus', ...noPinnedFooter},
	counterPartyQuantity: {
		headerName: 'Cpty Available Qty',
		field: 'counterPartyQuantity',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	counterPartyNotional: {headerName: 'Cpty Available Notional', field: 'counterPartyNotional', ...commaNoDecimal},
	country: {headerName: 'Country', field: 'country'},
	coupon: {headerName: 'Coupon', field: 'coupon'},
	cptyHaircut: {headerName: 'CptyHaircut(%)', field: 'cptyHaircutLiq', ...commaTwoDecimal}, //FIX
	createdBy: {headerName: 'Created By', field: 'createdBy'},
	createdDate: {
		headerName: 'Created Date',
		field: 'createdDate',
		excelFormat: 'dateTime',
		...yyyyMmDdHHmmss,
	},
	createdTime: {headerName: 'Created Time', field: 'createdTime', ...yyyyMmDdHHmmss},
	creditRating: {headerName: 'Credit Rating', field: 'creditRating'},
	criteria: {headerName: 'Criteria', field: 'criteria'},
	currentDuration: {headerName: 'Cur Dur', field: 'currentDuration', ...commaNoDecimal},
	currency: {headerName: 'Currency', field: 'currency'},
	currencyCode: {headerName: 'Currency Code', field: 'currencyCode'},
	currentAvailableQuantity: {
		headerName: 'Current Available Quantity',
		field: 'currentAvailableQuantity',
		...commaNoDecimal,
	},
	currentQuantity: {headerName: 'Current Quantity', field: 'currentQuantity', ...commaNoDecimal, ...noPinnedFooter},
	cusip: {headerName: 'CUSIP', field: 'cusip'},
	cusipCode: {headerName: 'Cusip Code', field: 'cusipCode'},
	custodianLendingFee: {headerName: 'Custodian Lending Fee', field: 'custodianLendingFee'},
	custodianLendingFeePercent: {headerName: 'Custodian Lending Fee(%)', field: 'custodianLendingFee'},
	custodianAssetUtilizationPercent: {
		headerName: 'Custodian Asset Utilization(%)',
		field: 'custodianAssetUtilization',
		...numberOnly,
		...noPinnedFooter,
	},
}

const d = {
	dailyInterestPayable: {headerName: 'Daily Interest Payable', field: 'dailyInterestPayable'},
	dailyInterestReceivable: {
		headerName: 'Daily Interest Receivable',
		field: 'dailyInterestReceivable',
		...commaTwoDecimal,
	},
	dailyOppCostUtilDelta: {
		headerName: 'Daily Opportunity Cost of Utilization Delta',
		field: 'dailyOppUtilizationDelta',
		...commaNoDecimal,
	},
	date: {headerName: 'Date', field: 'date'},
	day: {headerName: 'Day', field: 'day'},
	days: {headerName: 'Days', field: 'days'},
	deposType: {headerName: 'Depos Type', field: 'deposType'},
	deposValue: {headerName: 'Depos Value', field: 'deposValue'},
	depot: {headerName: 'Depot', field: 'depot'},
	detailsView: {headerName: 'Details View', field: 'detailsView'},
	description: {headerName: 'Description', field: 'description'},
	deviation: {headerName: 'Deviation', field: 'deviation', ...commaTwoDecimal, ...noPinnedFooter},
	direction: {headerName: 'Direction', field: 'direction'},
	dirtyPrice: {headerName: 'All in Price', field: 'dirtyPrice'},
	allInPrice: {headerName: 'All In Price', field: 'dirtyPrice', ...commaSixDecimal, ...noPinnedFooter}, //FIX
	discountAmount: {headerName: 'Discount Amount', field: 'discountAmount', ...commaNoDecimal},
	discountRate: {headerName: 'Discount Rate (%)', field: 'discountRate', ...noCommaFourDecimal, ...noPinnedFooter},
	distinctSecurities: {
		headerName: 'Distinct Securities',
		field: 'distinctSecurities',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	displayTime: {headerName: 'Time', field: 'displayTime'},
	dividendRate: {headerName: 'Dividend Rate', field: 'dividend_rate', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	domicile: {headerName: 'Domicile', field: 'domicile'},
	duration: {headerName: 'Duration', field: 'duration', ...noCommaNoDecimal},
	durationInSec: {headerName: 'Duration (s)', field: 'durationInSec', ...noCommaNoDecimal},
}

const e = {
	effectiveGrossLongQty: {
		headerName: 'Gross Long Qty',
		field: 'effectiveGrossLongQty',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	effectiveGrossLongNotional: {
		headerName: 'Gross Long Notional',
		field: 'effectiveGrossLongNotional',
		...commaNoDecimal,
	},
	effectiveGrossShortQty: {
		headerName: 'Gross Short Qty',
		field: 'effectiveGrossShortQty',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	effectiveGrossShortNotional: {
		headerName: 'Gross Short Notional',
		field: 'effectiveGrossShortNotional',
		...commaNoDecimal,
	},
	effectiveNetLongShortQty: {
		headerName: 'Net Long/Short Qty',
		field: 'effectiveNetLongShortQty',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	effectiveNetLongShortNotional: {
		headerName: 'Net Long/Short Notional',
		field: 'effectiveNetLongShortNotional',
		...commaNoDecimal,
	},
	email: {headerName: 'Email ID', field: 'email'},
	endCash: {headerName: 'End Cash', field: 'endCash', ...commaNoDecimal},
	endCashAmount: {headerName: 'End Cash', field: 'endCashAmount', ...commaNoDecimal},
	endDate: {headerName: 'End Date', field: 'endDate', ...yyyyMmDd},
	entityType: {headerName: 'Legal Entity Type', field: 'entityType'},
	eTFCost: {headerName: 'ETF Cost', field: 'etf_cost', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	eTFLoanRate: {headerName: 'ETF Loan Rate', field: 'etf_loan_fee', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	eventDetail: {headerName: 'Event Detail', field: 'eventDetail'},
	exchange: {headerName: 'Exchange', field: 'exchange'},
	exchangeCountry: {headerName: 'Exchange Country', field: 'exchangeCountry'},
	exchangeRate: {headerName: 'Fx Rate', field: 'exchangeRate', ...noCommaSixDecimal, ...noPinnedFooter},
	exDividendDate: {headerName: 'Ex Dividend Date', field: 'exDividendDate', ...yyyyMmDd},
	expiration: {headerName: 'Expiration', field: 'expiration'},
	exposure: {headerName: 'Exposure', field: 'exposure'},
	externalProviderRefId: {headerName: 'External Provider Ref Id', field: 'externalProviderRefId'},
	extendableDate: {headerName: 'Extension Date', field: 'extendableDate', ...yyyyMmDd},
	extSettleLoc: {headerName: 'Place of Settlement', field: 'extSettleLoc'},
}

const f = {
	fairValue: {headerName: 'FairValue', field: 'fair_value', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	firstName: {headerName: 'First Name', field: 'firstName'},
	fee: {headerName: 'Fee(bps)', field: 'fee'},
	feeAnchor1: {headerName: 'fee_anchor_1', field: 'feeAnchor1'},
	fee2Daily: {headerName: 'fee_rate_daily_2', field: 'fee2Daily'},
	fee2Anchor: {headerName: 'fee_anchor_2', field: 'fee2Anchor'},
	feeRate: {headerName: 'Fee Rate', field: 'feeRate', ...commaSixDecimal, ...noPinnedFooter},
	feeRateDaily: {headerName: 'fee_rate_daily', field: 'feeRateDaily'},
	feeRate1Daily: {headerName: 'fee_rate_daily_1', field: 'feeRate1Daily'},
	feeSplit: {headerName: 'feeSplit', field: 'feeSplit'},

	fileName: {headerName: 'File Name', field: 'fileName'},
	firmInternalizationPercent: {
		headerName: 'Firm Internalization %',
		field: 'firmInternalizationPercent',
		...noCommaTwoDecimal,
		...noPinnedFooter,
	},
	fitchRating: {headerName: 'Fitch Rating', field: 'fitchRating'},
	fillFlag: {headerName: 'Partial/Full', field: 'fillFlag'},
	filledFlag: {headerName: 'Partial/Full', field: 'filledFlag'},
	fomcCurve: {headerName: 'FOMC Curve', field: 'fomcCurve', ...commaTwoDecimal},
	forwardStart: {headerName: 'Forward Start Date', field: 'forwardStart', ...yyyyMmDd},
	fromLE: {headerName: 'From LE', field: 'fromLE'},
	fullName: {headerName: 'Client Name', field: 'fullName'}, //FIX
	fund: {headerName: 'Fund', field: 'fund'},
	fundFullName: {headerName: 'Fund (Parent)', field: 'fundFullName'},
	fundType: {headerName: 'Fund Type', field: 'fundType'},
	fundingRequired: {headerName: 'Funding Required', field: 'fundingRequired', ...commaNoDecimal},
	futuresShortRate: {headerName: 'Futures Short Rate', field: 'short_futures', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
}

const g = {
	groups: {headerName: 'Groups', field: 'groups'},
	gcBorrowRate: {headerName: 'GC Borrow Rate (%)', field: 'gcBorrowRate', ...commaTwoDecimal, ...noPinnedFooter},
	gainLossValue: {headerName: 'Gain Loss Value', field: 'gainLossValue', ...commaTwoDecimal},
	gainLossTotal: {headerName: 'Gain Loss Total (USD)', field: 'gainLossTotal', ...commaNoDecimal},
	grossEarnings: {headerName: 'Gross Earning', field: 'grossEarnings', ...commaTwoDecimal, ...noPinnedFooter},
	grossExposure: {headerName: 'Gross Exposure', field: 'grossExposure', ...commaNoDecimal},
	grossNotional: {headerName: 'Gross Notional', field: 'grossNotional', ...commaNoDecimal},
}

const h = {
	haircut: {headerName: 'Haircut (%)', field: 'haircut', ...noCommaTwoDecimal, ...noPinnedFooter},
}

const i = {
	id: {headerName: 'Id', field: 'id'},
	identifier: {headerName: 'Identifier', field: 'identifier'},
	index: {headerName: 'Index', field: 'index'},
	indemnifier: {headerName: 'Indemnifier', field: 'indemnifier'},
	indemnificationPrice: {headerName: 'Fee (bps)', field: 'indemnificationPrice', ...commaTwoDecimal},
	indicator: {headerName: 'Indicator', field: 'indicator'},
	inefficiencyPerCentOfSblNotional: {
		headerName: 'Recall/Return % of Total Notional',
		field: 'inefficiencyPerCentOfSblNotional',
		...commaTwoDecimalPercent,
		...noPinnedFooter,
	},
	inefficienciesArray: {
		headerName: 'Inefficiencies Type',
		field: 'inefficienciesArray',
		cellRenderer: params => {
			let renderValue = ''
			Array.isArray(params.value) &&
				params.value.forEach(val => {
					renderValue += `<span class='${getInefficiencyClassName(val)} mr-2'}>${val}</span>`
				})
			return renderValue
		},
	},
	impliedAllInRate: {
		headerName: 'Implied All In Rate (%)',
		field: 'impliedAllInRate',
		...noCommaSixDecimal,
		...noPinnedFooter,
	},
	indicativeRate: {headerName: 'Indicative Rate', field: 'indicativeRate', ...commaNoDecimal, ...noPinnedFooter},
	indicativeRateBps: {
		headerName: 'Indicative Rate (bps)',
		field: 'indicativeRateBps',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	initialValue: {headerName: 'Available Collateral', field: 'sodValue', ...commaNoDecimal}, //FIX
	instrumentCcy: {headerName: 'Instrument Ccy', field: 'instrumentCcy'},
	interpolatedRate: {
		headerName: 'Interpolated Rate (%)',
		field: 'interpolatedRate',
		...commaTwoDecimal,
		...noPinnedFooter,
	},
	impactCurrency: {headerName: 'Currency', field: 'impact.currency'}, //FIX
	impliedBid: {headerName: 'Bid', field: 'btic_implied_bid', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	impliedAsk: {headerName: 'Offer', field: 'btic_implied_ask', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	impliedBidRate: {headerName: 'Bid Rate', field: 'btic_implied_bid_rate', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	impliedAskRate: {headerName: 'Offer Rate', field: 'btic_implied_ask_rate', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	indexSpot: {headerName: 'IndexSpot', field: 'index_spot', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	internalCode1: {headerName: 'Internal Code 1', field: 'internalCode1'},
	internalCode2: {headerName: 'Internal Code 2', field: 'internalCode2'},
	internalCode3: {headerName: 'Internal Code 3', field: 'internalCode3'},
	internalCode4: {headerName: 'Internal Code 4', field: 'internalCode4'},
	internalCode5: {headerName: 'Internal Code 5', field: 'internalCode5'},
	internalizedNotional: {headerName: 'Internalized Notional', field: 'internalizedNotional', ...commaNoDecimal},
	internalizedPercent: {
		headerName: 'Internalized Rate (%)',
		field: 'internalizedPercent',
		...noCommaTwoDecimalPercent,
		...noPinnedFooter,
	},
	internalizedTotal: {headerName: 'Internalization Qty', field: 'internalizedTotal', ...commaNoDecimal},
	interestRate: {headerName: 'Interest Rate', field: 'interest_rate', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	investmentOpportunities: {
		headerName: 'Investment Opportunities',
		field: 'investmentOpportunities',
		...commaNoDecimal,
	},
	investmentRequired: {
		headerName: 'Investment Required',
		field: 'investmentRequired',
		...commaNoDecimal,
	},
	investmentRevenue: {
		headerName: 'Investment Revenue',
		field: 'investmentRevenue',
		...commaNoDecimal,
	},
	isError: {headerName: 'Error Present', field: 'isError'},
	isRestricted: {headerName: 'Restricted', field: 'isRestricted'},
	isin: {headerName: 'ISIN', field: 'isin'},
	isInternal: {headerName: 'Is Internal', field: 'isInternal'},
	isRegNetting: {headerName: 'RegNetting', field: 'isRegNetting'},
	issueCountry: {headerName: 'Issue Country', field: 'issueCountry'},
}

const l = {
	lastName: {headerName: 'Last Name', field: 'lastName'},
	lastUpdate: {headerName: 'Last Update', field: 'lastUpdate', ...yyyyMmDdHHmmss},
	lastValue: {headerName: 'Remaining Collateral', field: 'lastValue', ...commaNoDecimal},
	lcrType: {headerName: 'LCR TYPE', field: 'lcrType'},
	lcr: {headerName: 'LCR', field: 'lcr'},
	legalEntity: {headerName: 'Legal Entity', field: 'legalEntity'},
	legalEntities: {headerName: 'Legal Entities', field: 'legalEntities'},
	legalEntityFullName: {headerName: 'Legal Entity', field: 'legalEntityFullName'},
	legalEntityExternalId: {headerName: 'Legal Entity Id', field: 'legalEntityExternalId'},
	legalEntityIdentifier: {headerName: 'Legal Entity Identifier', field: 'legalEntityIdentifier'},
	lendable: {headerName: 'Lendable', field: 'lendable'},
	lcrCost: {headerName: 'LCR Cost', field: 'lcrCost', ...commaNoDecimal},
	lcrHaircut: {headerName: 'LCR Haircut', field: 'lcrHaircut'},
	lcrHqla: {headerName: 'LCR HQLA', field: 'lcrHqla'},
	lcrNotionalAmount: {headerName: 'LCR Notional', field: 'lcrNotional', ...commaNoDecimal}, //FIX
	loan: {headerName: 'Loan', field: 'loan', ...fontWeight, ...successDangerClass(true), ...commaNoDecimal},
	loanContributionAmount: {headerName: 'Loan', field: 'loanContributionAmount', ...commaNoDecimal},
	loanCurrent: {
		headerName: 'Current Use',
		field: 'loan',
		...fontWeight,
		...successDangerClass(true),
		...commaNoDecimal,
	}, //FIX
	loanCurrentRate: {headerName: 'Current Use Rate', field: 'loanRates', ...noCommaTwoDecimal}, //FIX
	loanFee: {headerName: 'Loan Fee', field: 'loanFee', ...commaTwoDecimal, ...noPinnedFooter},
	longName: {headerName: 'Name', field: 'longName'},
	locateBucketId: {headerName: 'Locate id', field: 'locateBucketId'},
	locateRequestId: {headerName: 'Locate Id', field: 'locateRequestId'},
	locateRequestEntryId: {headerName: 'Locate Request Entry Id', field: 'locateRequestEntryId'},
	locateRequestedBy: {headerName: 'Locate Requested By', field: 'locateRequestedBy'},
	locateRequestType: {headerName: 'Type', field: 'locateRequestType'},
	lender: {headerName: 'Lender', field: 'lender'},
	level: {headerName: 'Level', field: 'level'},
	lendingFeesDelta: {headerName: 'Lending Fee Delta', field: 'lendingFeeDelta', ...noCommaTwoDecimal},
	lendingRevenueDelta: {headerName: 'Lending Revenue Delta', field: 'lendingRevenueDelta'},
	lendingRevenueDeltaDaily: {
		headerName: 'Daily Lending Revenue Delta',
		field: 'lendingRevenueDelta',
		...commaNoDecimal,
	},
}

const m = {
	marginDiff: {headerName: 'Margin Difference', field: 'marginDiff', ...commaNoDecimal},
	marginPrev: {headerName: 'Previous Collateral', field: 'marginPrev', ...commaNoDecimal},
	marginToday: {headerName: 'Margin Today', field: 'marginToday', ...commaNoDecimal},
	marginType: {headerName: 'Type', field: 'marginType', ...noPinnedFooter},
	marketClassification: {headerName: 'Market Classification', field: 'marketClassification', ...noPinnedFooter},
	marketPrice: {headerName: 'Market Price', field: 'marketPrice', ...noPinnedFooter, ...commaTwoDecimal},
	marketPriceAmount: {headerName: 'Market Price', field: 'marketPrice.amount', ...noPinnedFooter, ...commaTwoDecimal}, //TODO--Change it to flatten form.
	marketFee: {headerName: 'Market Fee', field: 'marketFee', ...noPinnedFooter, ...noCommaFourDecimal},
	marketSecurityId: {headerName: 'Market Security Id', field: 'marketSecurityId'},
	messageType: {headerName: 'Message Type', field: 'messageType', ...noPinnedFooter},
	messageStatus: {headerName: 'Status', field: 'messageStatus', ...noPinnedFooter},
	myFee: {headerName: 'My Fee(bps)', field: 'myFee', ...noPinnedFooter, ...noCommaNoDecimal},
	marginProcessed: {headerName: 'Margin Processed', field: 'marginProcessed', ...commaNoDecimal},
	marginState: {headerName: 'Margin State', field: 'marginState'},
	matchedAgainstCptyAvailability: {
		headerName: '% of Matched Notional vs Total Availability Notional',
		field: 'matchedAgainstCptyAvailability',
		...commaTwoDecimal,
		...noPinnedFooter,
	},
	matchedAgainstNeeds: {
		headerName: '% Matched Vs Total Needs',
		field: 'matchedAgainstNeeds',
		...commaTwoDecimal,
		...noPinnedFooter,
	},
	matchedAgainstTotalNeeds: {
		headerName: '% Matched Vs Total Needs',
		field: 'matchedAgainstTotalNeeds',
		...commaTwoDecimal,
		...noPinnedFooter,
	},
	match: {headerName: 'Optimal Match %', field: 'match', ...commaTwoDecimal, ...noPinnedFooter},
	matchedNotional: {headerName: 'Matched Notional', field: 'matchedNotional', ...commaNoDecimal},
	matchPercentage: {headerName: 'Match %', field: 'matchPercentage', ...commaTwoDecimalPercent, ...noPinnedFooter},
	matchQuantity: {headerName: 'Matched Quantity', field: 'matchQuantity', ...commaNoDecimal, ...noPinnedFooter},
	maturity: {headerName: 'Maturity', field: 'maturity'},
	maturityCriteria: {headerName: 'Maturity Criteria', field: 'maturityCriteria'},
	maturityDate: {headerName: 'Maturity Date', field: 'maturityDate', ...yyyyMmDd},
	maturityType: {headerName: 'Maturity Type', field: 'maturityType'},
	matchedMaturityAmount: {headerName: 'Matched Maturity', field: 'matchedMaturityAmount', ...commaNoDecimal},
	maxDuration: {headerName: 'Max Dur', field: 'maxDuration', ...commaNoDecimal},
	maxNotl: {headerName: 'Max Notl', field: 'maxNotl', ...commaNoDecimal},
	minimumRating: {headerName: 'Rating', field: 'minimumRating'},
	model: {headerName: 'Model', field: 'model'},
	modelRate: {headerName: 'Rate', field: 'modelRate'},
	modelRateBusinessRates: {headerName: 'model_rate', field: 'modelRate'},
	moodyRating: {headerName: 'Moody Rating', field: 'moodyRating'},
	modifiedBy: {headerName: 'Modified By', field: 'modifiedBy'},
	modifiedDate: {headerName: 'Modified Date', field: 'modifiedDate', ...yyyyMmDdHHmmss},
	monthEnd: {headerName: 'Month End (%)', field: 'monthEnd', ...commaTwoDecimal},
	myQuantity: {headerName: 'My Needs Qty', field: 'myQuantity', ...commaNoDecimal, ...noPinnedFooter},
	margin: {headerName: 'Margin', field: 'margin', ...commaTwoDecimal, ...noPinnedFooter},
}

const n = {
	name: {headerName: 'Name', field: 'name'},
	needsContributionAmount: {headerName: 'Needs Notional', field: 'needsContributionAmount', ...commaNoDecimal},
	needQuantity: {headerName: 'Need Quantity', field: 'needQuantity', ...commaNoDecimal, ...noPinnedFooter},
	needsNotional: {headerName: 'Notional', field: 'needsNotional', ...commaNoDecimal},
	needsQuantity: {headerName: 'Need Quantity', field: 'needsQuantity', ...commaNoDecimal, ...noPinnedFooter},
	netEarnings: {headerName: 'Net Earning', field: 'netEarnings', ...commaTwoDecimal, ...noPinnedFooter},
	netExposure: {headerName: 'Net Exposure', field: 'netExposure', ...commaNoDecimal},
	ngtCustomerCode: {headerName: 'NGT Customer Code', field: 'ngtCustomerCode'},
	collateralDifference: {headerName: 'Margin Delta', field: 'netExposure', ...commaNoDecimal}, //FIX
	noOfTrades: {headerName: 'No of Trades', field: 'noOfTrades'},
	notional: {headerName: 'Notional', field: 'notional', ...commaNoDecimal},
	notionalAmount: {headerName: 'Notional', field: 'notionalAmount', ...commaNoDecimal},
	notionalCurrency: {headerName: 'Notional Currency', field: 'notionalCurrency'},
	notionalLimit: {headerName: 'Notional Limit', field: 'notionalLimit'},
	notl: {headerName: 'Cur Notl', field: 'notl', ...commaNoDecimal},
}

const o = {
	ois: {headerName: 'OIS (%)', field: 'ois', ...commaTwoDecimal},
	orderComments: {headerName: 'Order Comment', field: 'orderComments'},
	orderId: {headerName: 'Order Id', field: 'orderId'},
	orderStage: {headerName: '', field: 'orderStage'},
	orderStatus: {headerName: 'Order Status', field: 'orderStatus'},
	originalTradeType: {headerName: 'Original Trade Type', field: 'originalTradeType'},
	orderType: {headerName: 'Program', field: 'orderType'},
	originalAllInPriceAmount: {
		headerName: 'Original All in Price',
		field: 'originalAllInPriceAmount',
		...noCommaSixDecimal,
	},
	originalFilename: {headerName: 'File', field: 'originalFilename', ...noPinnedFooter},
	originalNotional: {
		headerName: 'Local Currency Notional',
		field: 'originalNotional',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	originalPriceAmount: {
		headerName: 'Original Price',
		field: 'originalPriceAmount',
		...commaSixDecimal,
		...noPinnedFooter,
	},
	originalQuantity: {headerName: 'Original Quantity', field: 'originalQuantity', ...commaNoDecimal},
	originalUtilization: {
		headerName: 'S&P Market Asset Utilization',
		field: 'originalUtilization',
		...numberOnly,
		...noPinnedFooter,
	},
	optimalMatchNotional: {headerName: 'Optimal Match Notional', field: 'optimalMatchNotional', ...commaNoDecimal},
	ownHaircut: {headerName: 'Firm Haircut(%)', field: 'ownHaircutLiq', ...commaTwoDecimal}, //FIX
}

const p = {
	parAmount: {headerName: 'PAR', field: 'parAmount', ...commaNoDecimal},
	par: {headerName: 'PAR', field: 'par', ...commaNoDecimal},
	partial: {
		headerName: 'Activity Type',
		field: 'partial',
		cellRenderer: params => {
			const {value} = params
			return value === true ? 'Partial' : value === false ? 'Full' : value
		},
	},
	pendingIn: {headerName: 'Pending In', field: 'pendingIn', ...commaNoDecimal},
	pendingInNotional: {headerName: 'Pending In Notional', field: 'pendingInNotional', ...commaNoDecimal},
	pendingOut: {headerName: 'Pending Out', field: 'pendingOut', ...commaNoDecimal},
	pendingOutNotional: {headerName: 'Pending Out Notional', field: 'pendingOutNotional', ...commaNoDecimal},
	platformTradeId: {headerName: 'Trade ID', field: 'platformTradeId'},
	price: {headerName: 'Price', field: 'price', ...commaSixDecimal, ...noPinnedFooter},
	priceAmount: {headerName: 'Price', field: 'priceAmount', ...commaSixDecimal, ...noPinnedFooter},
	priceCurrency: {headerName: 'Currency', field: 'priceCurrency'},
	priceMultiplier: {headerName: 'Price Multiplier', field: 'priceMultiplier', ...noCommaSixDecimal, ...noPinnedFooter},
	productType: {headerName: 'Product Type', field: 'productType'},
	productSubType: {headerName: 'Product SubType', field: 'productSubType'},
	poolFactor: {headerName: 'Pool Factor', field: 'poolFactor', ...commaTwoDecimal, ...noPinnedFooter},
	portfolioEntryType: {
		headerName: 'Type',
		field: 'portfolioEntryType',
		cellRenderer: params => {
			const {value, data} = params
			if (value === 'Intra Day') {
				const tooltipText = `Created Time: ${formatWithDateAndTime(data.createdDate)} \nCreated By: ${data.createdBy}`
				return `<div class='text-maitai cursor-help' title='${tooltipText}'>${value}</div>`
			}
			return value
		},
	},
	portfolio: {headerName: 'Portfolio', field: 'type'}, //FIX
	portfolioId: {headerName: 'Portfolio Id', field: 'portfolioId'}, //FIX
	post: {headerName: 'Post Amount', field: 'post', ...commaNoDecimal},
	postedAmount: {headerName: 'Posted Amount', field: 'postedAmount', ...commaNoDecimal},
	postedQuantity: {headerName: 'Posted Quantity', field: 'postedQuantity', ...commaNoDecimal},
	potentialExposure: {headerName: 'Potential Exposure', field: 'potentialExposure', ...commaNoDecimal},
	predictedRate: {headerName: 'Predicted Curve (%)', field: 'predictedRate', ...commaTwoDecimal},
	previousCollateralCurrency: {headerName: 'Collateral Currency', field: 'previousCollateralCurrency'},
	previousCollateralAmount: {headerName: 'Previous Collateral', field: 'previousCollateralAmount', ...commaNoDecimal},
	pnlExpectedDaily: {headerName: 'Pnl Expected Daily', field: 'currentLiqDailyPNL', ...commaNoDecimal}, //FIX
	proceedsAmount: {headerName: 'Proceeds', field: 'proceedsAmount', ...commaNoDecimal},
	proceeds: {headerName: 'Proceeds', field: 'proceeds', ...commaNoDecimal},
	productClass: {headerName: 'Trade Product Class', field: 'productClass'},
	priority: {headerName: 'Priority', field: 'priority'},
	profitAndLoss: {headerName: 'Profit And Loss', field: 'profitAndLoss'},
	publish: {headerName: 'Status', field: 'publish'},
	publishDate: {headerName: 'Publish Date', field: 'publishDate', ...yyyyMmDd},
	portfolioFullName: {headerName: 'Portfolio (Parent)', field: 'portfolioFullName'},
	puttable: {headerName: 'Puttable', field: 'puttable', ...noPinnedFooter},
	puttableDate: {headerName: 'Puttable Date', field: 'puttableDate', ...yyyyMmDd},
}

const q = {
	qty: {headerName: 'Quantity', field: 'qty', ...commaNoDecimal, ...noPinnedFooter},
	qualifier: {headerName: 'Qualifier', field: 'qualifier'},
	quantity: {headerName: 'Quantity', field: 'quantity', ...commaNoDecimal},
	quarterEnd: {headerName: 'Quarter End (%)', field: 'quarterEnd', ...commaTwoDecimal},
}

const r = {
	rank: {headerName: 'Client Ranking', field: 'rank'},
	rate: {headerName: 'Rate', field: 'rate', ...noCommaSixDecimal, ...noPinnedFooter},
	rating: {headerName: 'Rating', field: 'rating'},
	ratingAgency: {headerName: 'Rating Agency', field: 'ratingAgency'},
	rebate: {headerName: 'Rebate', field: 'rebate', ...noCommaTwoDecimal, ...noPinnedFooter},
	rebateProgramRate: {headerName: 'Rebate Program Rate', field: 'rebateProgramRate', ...noCommaFourDecimal},
	rebateRate: {headerName: 'Rebate Rate', field: 'rebateRate', ...noCommaSixDecimal, ...noPinnedFooter},
	rebateRateDaily: {headerName: 'rebate_rate_daily', field: 'rebateRateDaily'},
	rebateIndexYieldAnnualized1: {headerName: 'rebate_index_yield_annualized_1', field: 'rebateIndexYieldAnnualized1'},
	rebateIndexYieldDaily1: {headerName: 'rebate_index_yield_daily_1', field: 'rebateIndexYieldDaily1'},
	rebateIndexYieldAnnualized2: {headerName: 'rebate_index_yield_annualized_2', field: 'rebateIndexYieldAnnualized2'},
	rebateIndexYieldDaily2: {headerName: 'rebate_index_yield_daily_2', field: 'rebateIndexYieldDaily2'},
	rebateSpreadAnnual: {headerName: 'rebate_spread_annual', field: 'rebateSpreadAnnual'},
	rebateSpreadDaily: {headerName: 'rebate_spread_daily', field: 'rebateSpreadDaily'},
	recordDate: {headerName: 'Record Date', field: 'recordDate', ...yyyyMmDd},
	recall: {headerName: 'Recall', field: 'recall', ...commaNoDecimal},
	recallImpact: {headerName: 'Recall Impact', field: 'impact.amount', ...commaNoDecimal}, //FIX
	recalledAmount: {headerName: 'Recalled Amount', field: 'recallAmount', ...commaNoDecimal},
	recalledQuantity: {headerName: 'Recalled Quantity', field: 'recallQuantity', ...commaNoDecimal},
	recallReturnNotional: {headerName: 'Recall/Return Notional', field: 'recallReturnNotional', ...commaNoDecimal},
	recallReturnQuantity: {
		headerName: 'Recalled/Returned Quantity',
		field: 'recallReturnQuantity',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	reclaimRate: {headerName: 'Reclaim Rate', field: 'reclaimRate', ...noCommaFourDecimal},
	returnImpact: {headerName: 'Return Impact', field: 'impact.amount', ...commaNoDecimal}, //FIX
	recallInefficiencyContributionAmount: {
		headerName: 'Recall Contribution',
		field: 'recallInefficiencyContributionAmount',
		...commaNoDecimal,
	},
	recallReturnContributionCashType: {
		headerName: 'Notional Vs Cash',
		field: 'recallReturnContributionCashType',
		...commaNoDecimal,
	},
	recallReturnContributionNonCashType: {
		headerName: 'Notional Vs NonCash',
		field: 'recallReturnContributionNonCashType',
		...commaNoDecimal,
	},
	referenceRate: {headerName: 'Reference Rate', field: 'referenceRate'},
	referenceRateBusinessRates: {headerName: 'reference_rate', field: 'referenceRate'},
	relationships: {headerName: 'Relationships', field: 'relationships'},
	rejectComments: {headerName: 'Rejection Comment', field: 'rejectComments'},
	returnInefficiencyContributionAmount: {
		headerName: 'Return Contribution',
		field: 'returnInefficiencyContributionAmount',
		...commaNoDecimal,
	},
	repo: {headerName: 'Repo', field: 'repo', ...fontWeight, ...successDangerClass(true), ...commaNoDecimal},
	repoAdjustment: {headerName: 'Repo Estimate', field: 'repoAdjustment', ...fontWeight, ...commaNoDecimal},
	repoDiff: {headerName: 'Repo Delta', field: 'repoDiff', ...fontWeight, ...commaNoDecimal},
	repoInterest: {headerName: 'Repo Interest', field: 'repoInterest', ...commaNoDecimal},
	requestedByParty: {headerName: 'Requested By', field: 'requestedByParty'},
	requestType: {headerName: 'Request Type', field: 'requestType'},
	reverse: {headerName: 'Reverse', field: 'reverse', ...fontWeight, ...successDangerClass(false), ...commaNoDecimal},
	reverseAdjustment: {headerName: 'Reverse Estimate', field: 'reverseAdjustment', ...fontWeight, ...commaNoDecimal},
	reverseDiff: {headerName: 'Reverse Delta', field: 'reverseDiff', ...fontWeight, ...commaNoDecimal},
	ric: {headerName: 'RIC', field: 'ric'},
	requesterUserId: {headerName: 'Requestor', field: 'requesterUserId'},
	requestedQuantity: {
		headerName: 'Requested Quantity',
		field: 'requestedQuantity',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	currentCollateralAmount: {headerName: 'Today Margin', field: 'currentCollateralAmount', ...commaNoDecimal}, //FIX
	requiredCollateralAmount: {headerName: 'Required Collateral', field: 'requiredCollateralAmount', ...commaNoDecimal},
	requiredCollateralPct: {
		headerName: 'Required Collateral Pct',
		field: 'requiredCollateralPct',
		...commaTwoDecimalPercent,
	},
	requiredCost: {headerName: 'Funding Cost', field: 'requiredCost', ...commaNoDecimal},
	riskClass: {headerName: 'Product Group', field: 'riskClass'},
	riskType: {headerName: 'Risk Type', field: 'riskType'},
	currentCollateralCurrency: {headerName: 'Currency', field: 'currentCollateralCurrency'}, //FIX
}

const s = {
	schedule: {headerName: 'Schedule', field: 'schedule'},
	scheduleDescription: {headerName: 'Schedule Description', field: 'scheduleDescription'},
	sedol: {headerName: 'Sedol', field: 'sedol'},
	semRefId: {headerName: 'SEME Reference', field: 'semereferenceId'},
	settlementCcy: {headerName: 'Settlement Ccy', field: 'settlementCcy'},
	settlementMessage: {headerName: 'Trade Status', field: 'settlementMessage', ...noPinnedFooter},
	status: {headerName: 'Status', field: 'status', ...noPinnedFooter},
	settlementDate: {headerName: 'Settlement Date', field: 'settlementDate', ...yyyyMmDd},
	settlementMargin: {headerName: 'Margin Amount', field: 'settlementMargin', ...commaNoDecimal, ...noPinnedFooter},
	settlementMarginStatus: {headerName: 'Margin Status', field: 'settlementMarginStatus'},
	seqNumber: {headerName: 'Sql. No.', field: 'sqlNo', ...pinnedLeft, ...noColumnAction}, //FIX
	settlementStatus: {headerName: 'Settlement Status', field: 'settlementStatus'},
	sector: {headerName: 'Sector', field: 'sector'},
	security: {headerName: 'Security', field: 'security'},
	securityMargin: {headerName: 'Security Margin %', field: 'securityMargin', ...noCommaTwoDecimalPercent},
	securityMarginPercentage: {
		headerName: 'Security Margin %',
		field: 'securityMarginPercentage',
		...commaTwoDecimal,
		...noPinnedFooter,
	},
	securityName: {headerName: 'Security Name', field: 'securityName'},
	securityDescription: {headerName: 'Security Description', field: 'securityDescription'},
	securityWeightage: {
		headerName: '% Weight of Security',
		field: 'securityWeightage',
		...noCommaTwoDecimalPercent,
		...noPinnedFooter,
	},
	slNo: {headerName: 'Sl. No', field: 'slNo'},
	snpRating: {headerName: 'S&P Rating', field: 'snpRating'},
	sAndPMarketAssetUtilizationPercent: {
		headerName: 'S&P Market Asset Utilization(%)',
		field: 'originalUtilization',
		...numberOnly,
		...noPinnedFooter,
	},
	startCash: {headerName: 'Start Cash', field: 'startCash', ...commaNoDecimal},
	startCashObject: {headerName: 'Start Cash', field: 'startCash.amount', ...commaNoDecimal},
	startDate: {headerName: 'Start Date', field: 'startDate', ...yyyyMmDd},
	strategies: {headerName: 'Strategy', field: 'strategies'},
	securityId: {
		headerName: 'Security Id',
		field: 'securityId',
		cellRenderer: params => {
			const data = {
				isEncrpted: true,
				selectedGroupOne: 'securityId',
				selectedGroupOneItem: params.value,
			}

			// eslint-disable-next-line no-restricted-globals
			if (window !== top) {
				return params && params.value
					? `<span onclick='return window.open("${paths.abcmResult}?securityId=${
							params.value
					  }","venturi__venturi-app")'><i class="fa fa-th text-link"></i></span> &nbsp;
          <span onclick='return window.open("${paths.abcPortfolioTest}?${encryptURL(
							data
					  )}","venturi__venturi-app")' class='text-link'>${params.value}</span>`
					: null
			} else {
				return params && params.value
					? `<span onclick='return window.open("${paths.abcmResult}?securityId=${
							params.value
					  }")'><i class="fa fa-th text-link"></i></span> &nbsp;
          <span onclick='return window.open("${paths.abcPortfolioTest}?${encryptURL(data)}")' class='text-link'>${
							params.value
					  }</span>`
					: null
			}
		},
	},
	securityIdAssetsValues: {
		headerName: 'Security Id',
		field: 'securityId',
	},
	securityIdBusinessRates: {
		headerName: 'security_id',
		field: 'securityId',
	},
	securityIdType: {headerName: 'Security Id Type', field: 'securityIdType'},
	securityDesc: {headerName: 'Name', field: 'securityDesc'},
	securityType: {headerName: 'Security Type', field: 'securityType'},
	sensitivityType: {headerName: 'Sensitivity Type', field: 'sensitivityType'},
	sharpe: {headerName: 'Sharpe', field: 'sharpe', ...commaTwoDecimal},
	shortFallQuantity: {
		headerName: 'Shortfall Quantity',
		field: 'shortFallQuantity',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	shortFall: {headerName: 'Short Fall', field: 'shortFall', ...commaNoDecimal, ...noPinnedFooter},
	shortName: {headerName: 'Short Name', field: 'shortName'},
	silo: {headerName: 'Silo', field: 'silo'},
	sodAvailableQuantity: {headerName: 'SOD Available Quantity', field: 'sodAvailableQuantity', ...commaNoDecimal},
	sourceCollateral: {headerName: 'Source', field: 'source'},
	sourceSystem: {headerName: 'Source System', field: 'sourceSystem'},
	sourceSystemTradeId: {headerName: 'Source System Trade Id', field: 'sourceSystemTradeId'},
	spread: {headerName: 'Spread (bps)', field: 'spread', ...noCommaNoDecimal, ...noPinnedFooter},
	spreadBid: {headerName: 'Bid', field: 'libor_bid_spread', ...fontWeight, ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	spreadAsk: {headerName: 'Ask', field: 'libor_ask_spread', ...fontWeight, ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	state: {
		headerName: 'Status',
		field: 'state',
		cellRenderer: params => {
			if (!params.value) return null

			const noActivity = params.value === 'No Activity'
			return noActivity
				? `<i class="fas fa-hourglass-half text-info"></i> <span class='text-primary'>${params.value}</span>`
				: `<i class="fas fa-sync-alt text-danger"></i> <span class='text-danger'>${params.value}</span>`
		},
	},
	stockLoanCurrentMarketFee: {headerName: 'Stock Loan Current MarketFee', field: 'stockLoanCurrentMarketFee'},
	strategy: {
		headerName: 'Strategy',
		field: 'strategy',
		cellRenderer: params => {
			return params.value ? params.value.replace(/_/g, ' ') : ''
		},
	},
	subBuFullName: {headerName: 'Sub BU (Parent)', field: 'subBuFullName'},
	swapBasket: {headerName: 'Basket Qty', field: 'swapBasket', ...commaNoDecimal, ...noPinnedFooter},
	swapNotional: {headerName: 'Basket Notional', field: 'swapNotional', ...commaNoDecimal},
	sAndPMarketLendingFee: {headerName: 'S&P Market Lending Fee', field: 'spMarketLendingFee'},
	sAndPMarketLendingFeePercent: {headerName: 'S&P Market Lending Fee(%)', field: 'spMarketLendingFee'},
}

const t = {
	targetedAvailQty: {headerName: 'Available Qty', field: 'targetedAvailQty', ...commaNoDecimal},
	tbill: {headerName: 'Tbill (%)', field: 'tbill', ...commaTwoDecimal},
	term: {headerName: 'Term', field: 'term'},
	tenor: {headerName: 'Tenor', field: 'tenor', ...noPinnedFooter},
	termDate: {headerName: 'Term Date', field: 'termDate', ...yyyyMmDd},
	ticker: {headerName: 'Ticker', field: 'ticker'},
	time: {headerName: 'Time', field: 'time'},
	timeStamp: {headerName: 'Time Stamp', field: 'timeStamp', ...yyyyMmDdHHmmss},
	timer: {headerName: 'Timer', field: 'timer'},
	timeQueued: {headerName: 'Time Queued', field: 'timeQueued', ...HHmmss},
	templateName: {headerName: 'Template Name', field: 'templateName'},
	tradeComments: {headerName: 'Comment', field: 'tradeComments'},
	tradeCurrency: {headerName: 'Trade Currency', field: 'tradeCurrency'},
	tradeId: {headerName: 'Trade Id', field: 'tradeId'},
	tradeSetId: {headerName: ' Trade Set ID', field: 'tradeSetId'},
	tradeDate: {headerName: 'Trade Date', field: 'tradeDate', ...yyyyMmDd},
	tradeDescription: {headerName: 'Trade Description', field: 'tradeData.position_header.trade_description'},
	tradeSubType: {headerName: 'Trade SubType', field: 'tradeSubType'},
	tradeType: {
		headerName: 'Trade Type',
		field: 'tradeType',
		cellRenderer: params => {
			return params.value ? params.value.replace(/_/g, ' ') : ''
		},
	},
	tradeStatus: {headerName: 'Trade Status', field: 'tradeStatus'},
	trackingNotl: {headerName: 'Tracking Notional', field: 'Tracking Notl', ...commaNoDecimal}, //FIX
	trackingCost: {headerName: 'Tracking Cost', field: 'tracking_cost', ...commaNoDecimal}, //FIX
	trackingRate: {headerName: 'Tracking Rate (%)', field: 'tracking_rate', ...commaTwoDecimal}, //FIX
	trackingBasketTotal: {headerName: '#Names', field: 'total', ...commaNoDecimal}, //FIX
	trackingBasketReturn: {headerName: 'Return(%)', field: 'return', ...commaTwoDecimalPercent}, //FIX
	transactionMarginPercentage: {
		headerName: 'Transaction Margin',
		field: 'transactionMarginPercentage',
		...commaTwoDecimalPercent,
		...noPinnedFooter,
	},
	transformationRateDaily: {headerName: 'transformation_rate_daily', field: 'transformationRateDaily'},
	transformationIndexAnnualized1: {
		headerName: 'transformation_index_annualized_1',
		field: 'transformationIndexAnnualized1',
	},
	transformationIndexDaily1: {headerName: 'transformation_index_daily_1', field: 'transformationIndexDaily1'},
	transformationIndexAnnualized2: {
		headerName: 'transformation_index_annualized_2',
		field: 'transformationIndexAnnualized2',
	},
	transformationIndexDaily2: {headerName: 'transformation_index_daily_2', field: 'transformationIndexDaily2'},
	transformationIndexSpreadAnnual: {
		headerName: 'transformation_index_spread_annual',
		field: 'transformationIndexSpreadAnnual',
	},
	transformationIndexSpreadDaily: {
		headerName: 'transformation_index_spread_daily',
		field: 'transformationIndexSpreadDaily',
	},
	totalAvailableNotional: {headerName: 'Total Available Notional', field: 'totalAvailableNotional', ...commaNoDecimal},
	totalAvailableQuantity: {headerName: 'Total Available Quantity', field: 'totalAvailableQuantity', ...commaNoDecimal},
	totalFirmLongQuantity: {headerName: 'Total Firm Long Quantity', field: 'totalFirmLongQuantity', ...commaNoDecimal},
	totalApprovedQuantity: {headerName: 'Total Approved Quantity', field: 'totalApprovedQuantity', ...commaNoDecimal},
	totalNotional: {headerName: 'Total Notional', field: 'totalNotional', ...commaNoDecimal},
	totalLendableQuantity: {
		headerName: 'Total Lendable Long Quantity',
		field: 'totalLendableQuantity',
		...commaNoDecimal,
	},
	totalLoanedQuantity: {headerName: 'Total Loaned Quantity', field: 'totalLoanedQuantity', ...commaNoDecimal},
	totalRequestedQuantity: {
		headerName: 'Total Requested Quantity',
		field: 'totalRequestedQuantity',
		...commaNoDecimal,
		...noPinnedFooter,
	},
	totalSaving: {headerName: 'Total', field: 'totalSaving', ...commaNoDecimal},
	toLE: {headerName: 'To LE', field: 'toLE'},
	totalFee: {headerName: 'Total Fee ($)', field: 'totalFee', ...commaTwoDecimal},
	totalMarketFee: {headerName: 'Total Fees ($)', field: 'totalMarketFee', ...commaTwoDecimal},
	indexPricerTotal: {headerName: 'Total', field: 'total', ...commaTwoDecimal, ...noPinnedFooter}, //FIX
	type: {headerName: 'Type', field: 'type'},
}

const u = {
	uninternalizedNotional: {headerName: 'Uninternalized Notional', field: 'uninternalizedNotional', ...commaNoDecimal},
	uninternalizedPercent: {
		headerName: 'Uninternalized Rate (%)',
		field: 'uninternalizedPercent',
		...noCommaTwoDecimalPercent,
		...noPinnedFooter,
	},
	uninternalizedSaving: {headerName: 'Uninternalized', field: 'uninternalizedSaving', ...commaNoDecimal},
	uninternalizedTotal: {headerName: 'Uninternalized Qty', field: 'uninternalizedTotal', ...commaNoDecimal},
	updatedTime: {headerName: 'Updated Time', field: 'updatedTime', ...yyyyMmDdHHmmss},
	usedQuantity: {headerName: 'Used Quantity', field: 'usedQuantity', ...commaNoDecimal},
	userEmail: {headerName: 'User Email', field: 'userEmail'},
	username: {headerName: 'User Name', field: 'username'},
	utilizationDelta: {headerName: 'Utilization Delta', field: 'utilizationDelta', ...numberOnly, ...noPinnedFooter},
	utilizationDeltaPercent: {
		headerName: 'Utilization Delta(%)',
		field: 'utilizationDelta',
		...numberOnly,
		...noPinnedFooter,
	},
}

const v = {
	var1pct: {headerName: 'VaR 1%', field: 'var1pct', ...commaTwoDecimalPercent},
	var5pct: {headerName: 'VaR 5%', field: 'var5pct', ...commaTwoDecimalPercent},
	validation: {headerName: 'Validation', field: 'validation'},
	volatility: {headerName: 'StdDev(%)', field: 'volatility', ...commaTwoDecimalPercent},
	value: {headerName: 'Value', field: 'value', ...commaTwoDecimal},
	valuationDate: {headerName: 'Valuation Date', field: 'valuationDate'},
}

const w = {
	weight: {headerName: 'Weight', field: 'weight', ...noCommaTwoDecimal},
	weighting: {headerName: 'Weighting', field: 'weighting'},
	weightPercentage: {headerName: 'Weight %', field: 'weightPercentage', ...noCommaTwoDecimal},
}

const y = {
	yearEnd: {headerName: 'Year End (%)', field: 'yearEnd', ...commaTwoDecimal},
	yield: {headerName: 'Cur Yield', field: 'yield', ...commaTwoDecimal},
}

export const globalColumnDefinition = lodash.cloneDeep({
	...$,
	...a,
	...b,
	...c,
	...d,
	...e,
	...f,
	...g,
	...h,
	...i,
	...m,
	...l,
	...n,
	...o,
	...p,
	...q,
	...r,
	...s,
	...t,
	...u,
	...v,
	...w,
	...y,
})
